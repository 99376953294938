import request from './request'

const Utility = {
  getCountry(){
    return request({
      url: "/v1/country",
      method: "get"
    });
  },
  binLookup(code){
    return request({
      url: "/v1/binlookup?bincode="+code,
      method: "get",
      
    });
  }
}

export default Utility